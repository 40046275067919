import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'formly-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent extends FieldType<FieldTypeConfig> {

  confirmPopup: any;
  @ViewChild('submitButton') submitButton! : ElementRef;

  constructor(private confirmationService: ConfirmationService) { 
    super();
   }

  ngOnInit(): void {
      this.confirmPopup = this.props['confirmPopup'];
    this.confirmPopup.position = this.confirmPopup.position ? this.confirmPopup.position : 'top';
    
    if (this.confirmPopup.isActive == 'N') {
        this.field.formControl.setValue('inActive');
       // this.submitForm();
    } else {
        this.showConfirmation();
    }
     
  }

  showConfirmation() {
    
    this.confirmationService.confirm(<any>{
      key: 'confirmPopup',
      message: this.confirmPopup.message,
      header: this.props.label ? this.props.label : this.confirmPopup.label,
      icon: this.confirmPopup.icon ? this.confirmPopup.icon : 'pi pi-check-circle',
      acceptVisible: this.confirmPopup.acceptLabel ? true : false,
      rejectVisible: this.confirmPopup.rejectLabel ? true : false,
     
      accept: () => {
        this.field.formControl.setValue(this.confirmPopup.acceptValue ? this.confirmPopup.acceptValue : 'accept'); 
        if(this.confirmPopup.acceptAction == 'submit'){
          this.submitForm();
        }
        
      },

      reject:()=>{
        this.field.formControl.setValue(this.confirmPopup.rejectValue ? this.confirmPopup.rejectValue : 'reject'); 
        if (this.confirmPopup.rejectAction == 'submit') {
          this.submitForm();
        }
      }
  })
  }
  
  submitForm(){
    setTimeout(() => {
      this.submitButton.nativeElement.click();
    }, 100);
  }

}
