<p-confirmDialog #cd [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '30vw'}" 
    key="confirmPopup" [position]="confirmPopup.position" >
    <!-- <ng-template pTemplate="header">
        <h3>Header Content</h3>
    </ng-template> -->
    <ng-template pTemplate="footer" *ngIf="confirmPopup.acceptLabel">
        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
            <button type="button"   class="btn btn-primary w-100" (click)="cd.accept()">{{confirmPopup.acceptLabel}}</button>
            <button type="button"  class="btn btn-outline-primary mr-2 w-100"   (click)="cd.reject()">{{confirmPopup.rejectLabel}}</button>
        </div>

    </ng-template>
</p-confirmDialog>

<div>
    <button #submitButton style="display: none;" type="submit"></button>
</div>